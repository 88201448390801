// ページ内アンカーリンクのスクロールエフェクト // Scrolling effect for anchor links within the page
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定) // Set header height to headerHight if header is fixed (PC/SP setting required)
jQuery(function($) {
    var headerHeight = 0; // ヘッダー追従でない場合 // if not header-following
	// var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合 // if header following
    $('a[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        if ( target.length > 0 ) {
            var position = target.offset().top - headerHeight;
            $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
        }
    });
})


// ページトップボタンの表示 // Display the page top button
jQuery(function($) {
	if ( $('#pageup').length > 0 ) {
		var topBtn = $('#pageup');    
		topBtn.css("display", "none");
		//スクロールが100に達したらボタン表示 // display button when scroll reaches 100
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});
		//スクロールしてトップ // scroll to the top
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
	}
});

//show fixedBar
jQuery(function($) {
	var fixedBar = $('.js-fixedBar');
	fixedBar.css("display", "none");
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			fixedBar.fadeIn();
		} else {
			fixedBar.fadeOut();
		}
	});
});

// ヘッダーの固定エフェクト // Fixed header effect
// jQuery(function($) {
// 	var $header = $('header');
// 	$(window).scroll(function() {
// 		if ($(window).scrollTop() > 50) {
// 			$header.addClass('fixed');
// 		} else {
// 			$header.removeClass('fixed');
// 		}
// 	});
// });


// アコーディオンの起動 // Activate the accordion
jQuery(function($) {
  if ($('.js-toggle .js-toggleHead').length > 0) {
    $(".js-toggle .js-toggleHead").click(function() {
		
		var $this = $(this);
		var $toggleWrap = $this.next(".js-toggleWrap");

		if($this.hasClass('open')){
			$toggleWrap.slideUp();
			$this.removeClass("open");
		} else {
			$toggleWrap.slideToggle();
			$this.toggleClass("open");
		}
    });
  }
});

jQuery(function($) {
	if ($('dl.accordion dt').length > 0) {
		$("dl.accordion dt").click(function(){
			$("dl.accordion dd").not($(this).next("dd")).slideUp();
			$("dl.accordion dt").not($(this)).removeClass("open");

			$(this).next("dd").slideToggle();
			$(this).toggleClass("open");
		});
	}
});

//  matchHeight
jQuery(function($) {
    var t = 0;
    if ($('.js-matchHeight').length) {
        $(window).on('load resize', function() {
            $('.js-matchHeight').css('height', 'auto');
            if ($(window).width() > 767) {
                $('.js-matchHeight').stop().each(function() {
                    if ($(this).outerHeight() > t) {
                        highest = t = $(this).outerHeight();
                    }
                });
                $('.js-matchHeight').css('height', highest + 'px');
            }
        });
    }
})

//  Scrollable
jQuery(function($) {
	if ($('.js-scrollable').length) {
		$(window).on('load resize', function() {
			if ($(window).width() < 767) {
				new ScrollHint('.js-scrollable', {
					applyToParents: true,
					i18n: {
						scrollable: 'スクロールできます'
					}
				});
			}
		})
	}
});
